








































import gsap from 'gsap'
import { Picture } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'

type PersonalityGallery =
  | {
      picture: Picture
      htmltext: string
    }[]
  | {
      gallery: {
        picture: Picture
        htmltext: string
      }[]
    }

export default defineComponent({
  name: 'PersonalityGallery',
  components: {},
  props: {
    content: {
      type: [Array, Object] as PropType<PersonalityGallery>,
      required: true,
    },
  },

  setup() {
    // Appear animation, triggered by v-appear
    const onAppear = (el: HTMLElement) => {
      const imgEls = el.querySelectorAll('figure img')
      const captionEls = el.querySelectorAll('figcaption')
      const arrowEls = el.querySelectorAll(
        '[class*="personality-gallery__arrow"]'
      )
      const tl = gsap.timeline({
        onComplete: () => {
          gsap.set([imgEls, captionEls, arrowEls], { clearProps: 'all' })
        },
      })

      tl.set(el, { clearProps: 'opacity' })
        .from(
          imgEls,
          {
            rotate: 0,
            y: 30,
            opacity: 0,
            stagger: 0.2,
            duration: 1.1,
          },
          0
        )
        .from(
          captionEls,
          {
            y: 40,
            opacity: 0,
            stagger: 0.2,
            duration: 1.1,
          },
          0
        )
        .from(arrowEls, { opacity: 0, stagger: 0.1, duration: 1.1 }, 0.2)
    }

    return {
      onAppear,
    }
  },
})
