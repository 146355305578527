































/* eslint-disable max-len */
import { gsap, Power4 } from 'gsap'

import { defineComponent, PropType, ref, onMounted } from '@vue/composition-api'

import { PersonalityHero } from '@/inc/types'

export default defineComponent({
  name: 'PersonalityHero',
  components: {},
  props: {
    content: {
      type: Object as PropType<PersonalityHero>,
      required: true,
    },
  },

  setup(_props, ctx) {
    const root = ref<HTMLElement | null>(null)
    const isMobile = ref(true)

    const onboard = () => {
      root.value!.classList.remove('before-onboard')

      const title = root.value!.querySelector('.personality-hero__title')
      const text = root.value!.querySelector('.personality-hero__text')
      const tl = gsap.timeline()

      tl.from(
        [title, text],
        {
          y: 50,
          opacity: 0,
          duration: 1.5,
          stagger: 0.1,
          ease: Power4.easeOut,
        },
        0.3
      )

      if (isMobile.value) {
        const mobilePicture = root.value!.querySelector(
          '.personality-hero__picture'
        )

        tl.from(
          mobilePicture,
          {
            opacity: 0,
            duration: 1.5,
            ease: Power4.easeOut,
          },
          0.5
        )
      } else {
        const pictures = root.value!.querySelectorAll(
          '.personality-hero__pictures__inner'
        )
        pictures.forEach((picture: Element, i: number) => {
          tl.from(
            picture,
            {
              opacity: 0,
              duration: 2,
              xPercent: i === 0 ? -15 : 15,
              ease: 'power1.easeOut',
            },
            0.5
          )
        })
      }
    }

    const onResize = (w: number) => (isMobile.value = w < 768)

    onMounted(() => {
      isMobile.value = window.innerWidth < 768

      ctx.root.$once('page:ready', () => {
        onboard()
      })
    })

    return {
      root,
      isMobile,
      onResize,
    }
  },
})
