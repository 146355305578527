














import { useGetters } from '@u3u/vue-hooks'
import { defineComponent } from '@vue/composition-api'

import PersonalityHero from '@/components/personality/Hero.vue'
import PersonalityGallery from '@/components/personality/Gallery.vue'
import PersonalityDuo from '@/components/personality/Duo.vue'

export default defineComponent({
  name: 'PersonalityLanding',
  components: {
    PersonalityHero,
    PersonalityGallery,
    PersonalityDuo,
  },
  setup() {
    return {
      ...useGetters(['content']),
    }
  },
})
